import axios from 'axios'
import router from '@/router'
import Element from 'element-ui'

const axiosRequest = axios.create({
	//阿里云服务器
	baseURL: 'https://zhangtaoweishi.com:9001',
	// baseURL: 'http://139.9.113.139:9001',
})

axiosRequest.interceptors.request.use((config) => {
	if (localStorage.getItem('satoken')) {
		config.headers.satoken = localStorage.getItem('satoken')
	}
	return config
})
axiosRequest.interceptors.response.use((res) => {
	//返回code后之后要做的操作例如：token失效后跳到登陆页面
	if (res.data.status === 11013) {
		let path = window.location.href.split('/')
		if (path[path.length - 1] !== 'login') {
			Element.Message.warning(res.data.message)
			router.push('/login')
		}
	}
	return res
})
export default axiosRequest
