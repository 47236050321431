<template>

  <div id="app">
    <!--    <nav>-->
    <!--      <router-link to="/">Home</router-link> |-->
    <!--      <router-link to="/about">About</router-link>-->
    <!--    </nav>-->
    <router-view v-if="isRouteActive"></router-view>
    <!--      <footer>-->
    <!--       -->
    <!--      </footer>-->

    <footer class="footer-box">
      <a href="https://beian.miit.gov.cn">津ICP备16005000号-3</a>
      &nbsp;
      <img src="./assets/备案图标.png" style="width: 17px"/>
      <a href="https://beian.mps.gov.cn/#/query/webSearch?code=12011602300177" rel="noreferrer" target="_blank">津公网安备12011602300177</a>
    </footer>
  </div>
</template>

<style>
#app {
	font-family: 'Helvetica Neue', 'Hiragino Sans GB', 'WenQuanYi Micro Hei',
		'Microsoft Yahei', sans-serif;
	height: 100vh;
	padding: 0;
	margin: 0;
	font-size: 15px;
  position: relative;
}
.footer-box{
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
</style>
<script setup lang="ts">
import { provide, ref, nextTick } from 'vue'

const isRouteActive = ref(true)
provide('reload', () => {
	isRouteActive.value = false
	nextTick(() => {
		localStorage.setItem('selectTab', '告警信息')
		isRouteActive.value = true
		this.selectTab = localStorage.getItem('selectTab')
	})
})
</script>
