import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './style/xiangqing/iconfont.css'
import './style/shouye/iconfont.css'
import './style/xiazai/iconfont.css'
import axios from './axios'
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)
Vue.prototype.$axios = axios
Vue.prototype.$url = 'https://zhangtaoweishi.com:9001';
// Vue.prototype.$url = 'http://192.168.31.69:8081'
Vue.prototype.$view_url = 'http://yunbaobei.yeetrc.com:8012'
import ElementUI from 'element-ui'
import * as echarts from 'echarts'
import 'element-ui/lib/theme-chalk/index.css'
// require("./mock")
Vue.use(ElementUI)
Vue.config.productionTip = false
axios.defaults.withCredentials = true
// 引入koi.js工具类(随机获取整数数字)
import { randomNum } from './util/koi'
Vue.prototype.randomNum = randomNum

import jsCookie from 'js-cookie'
import './assets/less/style.less'
// 创建空的 Vue 实例
export const EventBus = new Vue()

// 挂载到 Vue.prototype 上，这样可以在任何地方访问该实例
Vue.prototype.$bus = EventBus
import { monitorZoom } from './util/monitorZoom.js'
const m = monitorZoom()
// if (window.screen.width * window.devicePixelRatio >= 3840) {
// 	document.body.style.zoom = 100 / (Number(m) / 2) // 屏幕为 4k 时
// } else {
// 	document.body.style.zoom = 100 / Number(m)
// }
// router.beforeEach((to, from, next) => {
// 	// Check if the current route requires zoom adjustment
// 	console.log(to.meta)
// 	const requiresZoom = to.meta.requiresZoom
//
// 	if (requiresZoom) {
// 		if (window.screen.width * window.devicePixelRatio >= 3840) {
// 			document.body.style.zoom = 100 / (Number(m) / 2)
// 		} else {
// 			document.body.style.zoom = 100 / Number(m)
// 		}
// 	} else {
// 		// Reset zoom if not required
// 		document.body.style.zoom = 1
// 	}
//
// 	next()
// })

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')

Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.directive('has', {
	inserted: function (el, binding) {
		const action = binding.value.action
		const role = jsCookie.get('role')

		if (role.includes('5')) {
			if (role.includes('2')) {
				if (!action.includes('2')) {
					// 除财务外，禁用其他权限按钮
					// el.disabled = true
					// el.style.color = "#c0c4cf"
					el.style.display = 'none'
				}
				return
			}

			if (role.includes('3')) {
				if (!action.includes('3')) {
					el.style.display = 'none'
				}
				return
			}

			// if (role.includes("4")){
			//   if (!action.includes('4')) {
			//     el.style.display = 'none'
			//   }
			//   return;
			// }
			el.style.display = 'none'
		}
	},
})
// import  moment from "moment"
// Vue.prototype.$moment =moment
