<template>
  <div class="success_box">
    <el-image :src="require(`../assets/yes.png`)" style="width: 100px">
    </el-image>
    <h1>扫码成功！</h1>
  </div>
</template>

<script>
export default {
  name: "ScanQRSuccess"
}
</script>

<style scoped>
.success_box{
  /*width: 450px;*/
  margin: 150px auto;
  text-align: center;
  /*margin-top: 30px;*/
}
</style>