<template>
    <!--    <h3 style="text-align: center;margin-top: 50px">绑定账号</h3>-->
    <div class="register_box">
      <div>
        <el-form :model="bindForm" :rules="pwdRules" ref="pwdLoginForm" class="el-form">
          <!--用户名-->
          <el-form-item prop="username">
            <span style="font-size: 35px ">用户名：</span>
            <el-input v-model="bindForm.username" placeholder="  用户名" clearable style="font-size: 25px"
                      id="input1"></el-input>
          </el-form-item>

          <!--密码-->
          <el-form-item prop="password" style="margin-top: 50px">
            <span style="font-size: 35px;margin-right: 15px">密&nbsp;&nbsp;码：</span>
            <el-input v-model="bindForm.password" placeholder="  密码" type="password" show-password style="font-size: 25px"
                       id="input1"></el-input>
          </el-form-item>
          <!--登录-->
          <el-form-item style="margin-top: 100px">
            <el-button type="primary" @click="bindVX('bindForm')" class="submit-btn" style="font-size: 40px;width: 200px;height: 100px">绑定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>


</template>

<script>
import axiosRequest from "@/axios";

export default {
  name: "BindWechat",
  data() {
    return {
      bindForm: {
        username: '',
        password: ''
      },
      openId: '',
      //规则
      pwdRules: {
        username: [
          {required: true, message: '    ', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '    ', trigger: 'blur'}
        ],
      },
    }
  },
  mounted() {
    var url = location.search; //获取url中"?"符后的字串
    if (url.indexOf("?") != -1) {    //判断是否有参数
      var str = url.substr(1); //从第一个字符开始 因为第0个是?号 获取所有除问号的所有符串
      var strs = str.split("=");   //用等号进行分隔 （因为知道只有一个参数 所以直接用等号进分隔 如果有多个参数 要用&号分隔 再用等号进行分隔）
      this.openId = strs[1]    //直接弹出第一个参数 （如果有多个参数 还要进行循环的）
    }
  },
  methods: {
    bindVX() {
      axiosRequest.put('/user/bindWechat', {
        username: this.bindForm.username,
        password: this.bindForm.password,
        openId: this.openId
      }).then(res => {
        if (res.data.status === 200) {
          // this.$message({
          //   message: res.data.message,
          //   type: "success",
          // });
          this.$router.push('/weChatSuccess')
        } else if (res.data.status === 500) {
          this.$message({
            customClass: 'message-logout12',
            message: res.data.message,
            type: "error",
          });
        }
      })
    },

  }
}
</script>

<style scoped>
.el-input {
  width: 500px;

}

.register_box /deep/ #input1 {
  width: 500px;
  height:75px;
  min-height: 40px;
  position: relative;
}

.el-form {
  text-align: center
}

.register_box {
  width: 700px;
  margin: 45px auto;
  /*margin-top: 30px;*/
}

</style>
<style>
  .message-logout12{
    zoom: 2
  }
</style>
